import React, { useMemo, useState } from 'react';
import { FaUserGroup } from 'react-icons/fa6';
import { HiOutlineTrash } from 'react-icons/hi';
import { TbEdit, TbPlus } from 'react-icons/tb';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loadRecords, removeRecord } from 'store/slices/bookKeepingSlice';
import CreateBook from './CreateBook';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess } from 'Utils';
import DeleteModal from 'components/projects/procurement/material-schedule/components/DeleteModal';

const BookTabs = () => {
  let { data, loading, activeBook } = useAppSelector((m) => m.bookKeeping);
  let dispatch = useAppDispatch();
  let [showCreateModal, setShowCreateModal] = useState(false);
  let [showDeleteModal, setShowDeleteModal] = useState(false);
  let [editing, setEditing] = useState(false);

  const handleTabClick = (x: string) => {
    dispatch(loadRecords({ activeBook: x }));
  };

  let value = useMemo(() => {
    return data.find((m) => m._id === activeBook);
  }, [activeBook, data]);

  const handleBookDelete = async () => {
    let { e, response } = await postForm(
      'patch',
      `financials/bookkeeping/archive?bookId=${value?._id}`
    );
    if (response) {
      dispatch(removeRecord(value?._id));
      displaySuccess('Book removed sucessfully');
      setShowDeleteModal(false);
    } else {
      displayError(e?.message);
    }
  };

  return (
    <>
      {showCreateModal && (
        <CreateBook
          isEditing={editing}
          value={value}
          closer={() => {
            setShowCreateModal(false);
            setEditing(false);
          }}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          title="Delete Book"
          deleteWhat="Book"
          buttonText="Delete"
          closer={() => setShowDeleteModal(false)}
          handleDeleteSubmit={handleBookDelete}
        />
      )}

      <div className="w-full flex items-center mt-4 ">
        <div className=" flex items-center  overflow-x-auto ">
          {data.map((book, index) => (
            <div
              key={index}
              className={`flex items-center gap-2 py-2 px-6 border hover:cursor-pointer border-t-bbg rounded-t-lg ${
                activeBook === book._id
                  ? 'active bg-white border-b-white'
                  : 'bg-[#EBF1FA] border-x-bbg'
              }`}
              onClick={() => handleTabClick(book._id)}>
              <div className="flex items-center justify-start gap-2">
                <span>
                  <FaUserGroup
                    className={`${activeBook === book?._id ? 'text-bblue' : 'text-ashShade-2'}`}
                  />
                </span>
                <span
                  className={`max-w-[9em] whitespace-nowrap inline-block  overflow-x-auto capitalize ${
                    activeBook === book?._id ? 'text-bblue' : 'text-bblack-1'
                  }`}>
                  {book?.name || `Book ${index + 1}`}
                </span>
              </div>

              {activeBook === book?._id && (
                <div className="flex items-center gap-2">
                  <TbEdit
                    onClick={() => {
                      setEditing(true);
                      setShowCreateModal(true);
                    }}
                  />
                  <HiOutlineTrash
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                    className="text-redShade-0"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <span
          className=" border-2 p-1 border-bblue ml-2 rounded-full"
          onClick={() => {
            setShowCreateModal(true);
            setEditing(false);
          }}>
          <TbPlus color="blue" size={20} />
        </span>
      </div>
    </>
  );
};

export default BookTabs;
