import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess } from 'Utils';
import { ClusterType, ClusterTypes } from 'types';
import { Book } from 'components/projects/financials/book-keeping/types';

export interface BookStore {
  loading?: boolean;
  data: Book[];
  activeBook?: string;
}

let initialState: BookStore = {
  loading: false,
  data: [],
  activeBook: ''
};

export const bookKeepingSlice = createSlice({
  name: 'bookKeeping',
  initialState,
  reducers: {
    loadRecords: (state, action: PayloadAction<Partial<BookStore>>) => {
      let newState: any = state;
      for (let x in action.payload) {
        newState[x] = action.payload[x as keyof BookStore];
      }
      return newState;
    },
    addRecord: (state, action) => {
      let newState = state;
      newState.data.push(action.payload);
      return newState;
    },
    updateRecord: (state, action) => {
      let newState = state;
      newState.data = newState.data.map((m) => {
        if (m._id === action.payload._id) {
          return action.payload;
        }
        return m;
      });
      return newState;
    },
    removeRecord: (state, action) => {
      let newState = state;
      newState.data = newState.data.filter((m) => m._id !== action.payload);
      return newState;
    },
    removeMaterial: (state, action: PayloadAction<{ bookId: string; materialId: string }>) => {
      let newState = state;
      let { bookId, materialId } = action.payload;
      newState.data = newState.data.map((m) => {
        if (m._id === bookId) {
          m.materials = m.materials.filter((k) => k._id !== materialId);
        }

        return m;
      });

      return newState;
    }
  }
});

export const { loadRecords, updateRecord, removeRecord, addRecord, removeMaterial } =
  bookKeepingSlice.actions;

export const record = (state: RootState) => state.bookKeeping;
export default bookKeepingSlice.reducer;
